import React from 'react';
import { graphql } from 'gatsby';

const remarkNodeToArticle = ({
  html,
  excerpt,
  frontmatter,
  fields,
  ...articleData
}, options = {}) => ({
  body: options.bodyIn === 'html' ? html : excerpt,
  ...articleData,
  ...frontmatter,
  ...fields
});

export const usesArticle = (Component, options = {}) => props => {
  const {
    data: {
      markdownRemark
    }
  } = props;

  return <Component
    article={ remarkNodeToArticle(markdownRemark, options) }
    { ...props } />;
};

export const usesArticleList = (Component, options = {}) => props => {
  const {
    data: {
      allMarkdownRemark: {
        edges
      }
    }
  } = props;
  const articleList = edges
    .map(({ node }) => remarkNodeToArticle(node, options));

  return <Component articleList={ articleList } { ...props } />;
};

export const articleDetailsFragment = graphql`
  fragment ArticleDetails on MarkdownRemark {
    timeToRead
    fields {
      slug
      published
    }
    frontmatter {
      title
      date(formatString: "MMMM D, YYYY")
    }
  }
`;
