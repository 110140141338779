import React from 'react';

import SEO from 'components/SEO';
import Header from 'components/Header';

import style from './style.module.css';

const Wrapper = ({ container: C, children }) => (
  <C className={ style.wrapper }>{ children }</C>
);

const Layout = ({ children, container, ...props }) => (
  <React.Fragment>
    <SEO { ...props }/>
    <Header />
    <Wrapper container={ container || 'div' }>
      { children }
    </Wrapper>
  </React.Fragment>
);

export default Layout;
