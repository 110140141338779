import React from 'react';
import { Link } from 'gatsby';

import style from './style.module.css';

const formatTime = minutes =>
  `${ minutes } ${ minutes === 1 ? 'minute' : 'minutes' }`;

const Article = ({
  slug,
  title,
  published,
  date,
  timeToRead,
  body,
  children
}) => <React.Fragment>
  <div className={ style.header } full-width="true">
    <Link to={ slug }>{ title }</Link>
    { published ? null : <div>Unpublished</div> }
  </div>
  <div className={ style.metadata } full-width="true">
    <ul className="unstyled-list">
      <li>{ date || 'Unpublished' }</li>
      <li>Reading time: { formatTime(timeToRead) }</li>
    </ul>
  </div>
  <div
    className={ style.content }
    dangerouslySetInnerHTML={ body && { __html: body } }>{ children }</div>
</React.Fragment>;

export default Article;
