import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

import GitlabIcon from 'react-feather/dist/icons/gitlab';
import TwitterIcon from 'react-feather/dist/icons/twitter';
import RssIcon from 'react-feather/dist/icons/rss';

import style from './style.module.css';

const socialLinks = [
  {
    url: 'https://gitlab.com/javallone',
    icon: GitlabIcon,
    name: 'GitLab'
  },
  {
    url: 'https://twitter.com/javallone',
    icon: TwitterIcon,
    name: 'Twitter'
  },
  {
    url: 'https://bromidic.net/rss.xml',
    icon: RssIcon,
    name: 'RSS'
  }
];

const query = graphql`
  query HeaderQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const Header = () => <StaticQuery
  query={ query }
  render={ ({ site: { siteMetadata } }) => (
    <header className={ style.header }>
      <Link to="/">{ siteMetadata.title }</Link>
      <ul className="unstyled-list">
        { socialLinks.map(({ url, name, icon: Icon }, i) => (
          <li key={ i }>
            <a className={ style.socialLink } href={ url }
              target="_blank" rel="nofollow noopener noreferrer">
              <Icon size={ 16 } />
              <span>{ name }</span>
            </a>
          </li>
        )) }
      </ul>
    </header>
  ) } />;

export default Header;
