import React from 'react';
import { Link } from 'gatsby';

import ChevronRight from 'react-feather/dist/icons/chevron-right';
import ChevronLeft from 'react-feather/dist/icons/chevron-left';

import style from './style.module.css';

const Pagination = ({ prevPage, nextPage, numPages, pageNum }) => (
  <div className={ style.pagination }>
    <span className={ style.link }>
      { prevPage && <Link to={ prevPage }>
        <ChevronLeft alt="Previous Page" />
      </Link> }
    </span>
    <span className={ style.page }>{ pageNum + 1 } / { numPages }</span>
    <span className={ style.link }>
      { nextPage &&
        <Link to={ nextPage }>
          <ChevronRight alt="Next Page" />
        </Link> }
    </span>
  </div>
);

export default Pagination;
