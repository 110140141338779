import React from 'react';

import Article from 'components/Article';

import style from './style.module.css';

const PostPreview = ({
  excerpt,
  ...props
}) => <div className={ style.preview }>
  <Article { ...props }>{ excerpt }</Article>
</div>;

export default PostPreview;
