import React from 'react';
import { graphql } from 'gatsby';

import { usesArticleList } from 'lib/article-hoc';

import Layout from 'components/Layout';
import ArticlePreview from 'components/ArticlePreview';
import Pagination from 'components/Pagination';

const PostListTemplate = ({ articleList, pageContext }) => (
  <Layout canonical={ pageContext.slug }>
    <main>
      { pageContext.numPages > 1 && <Pagination { ...pageContext } /> }
      <ul className="unstyled-list">
        { articleList.map((article, i) =>
          <li key={ i }>
            <ArticlePreview { ...article } />
          </li>
        ) }
      </ul>
      { pageContext.numPages > 1 && <Pagination { ...pageContext } /> }
    </main>
  </Layout>
);

export default usesArticleList(PostListTemplate, {
  bodyIn: 'excerpt'
});

export const postListQuery = graphql`
  query PostListQuery($publishedOnly: Boolean!, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      skip: $skip
      limit: $limit
      filter: {
        fields: { published: { in: [true, $publishedOnly] } }
      }
      sort: {
        fields: [frontmatter___date], order: DESC
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 200, format: PLAIN)
          ...ArticleDetails
        }
      }
    }
  }
`;
