import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const query = graphql`
  query SEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

const SEO = props => <StaticQuery
  query={ query }
  render={ ({ site: { siteMetadata } }) => {
    const title = [props.title, siteMetadata.title].filter(Boolean).join(' | ');
    const description = props.description || siteMetadata.description;

    return <Helmet>
      <html lang="en" />
      <title>{ title }</title>
      <meta name="description" content={ description } />
      { props.canonical && <link rel="canonical" href={ props.canonical } /> }
    </Helmet>;
  } } />;

export default SEO;
